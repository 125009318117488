let defaultText = [{
  textChannel: ['MPPL', 'THMX'],
  textList: [{
    id: 'S',
    width: 25,
    height: 31,
    wise: 7,
    sh_image: '/pwa_dist/images/user/order_typeS-2a61cc1772.png', 
    rw_image: '/pwa_dist/rw_images/user/order_typeS-2a61cc1772.png'
  }, {
    id: 'M',
    width: 31,
    height: 50,
    wise: 16,
    sh_image: '/pwa_dist/images/user/order_typeM-038f10e5f5.png', 
    rw_image: '/pwa_dist/rw_images/user/order_typeM-038f10e5f5.png'
  }, {
    id: 'L',
    width: 31,
    height: 50,
    wise: 35,
    sh_image: '/pwa_dist/images/user/order_typeL-1e401baea6.png', 
    rw_image: '/pwa_dist/rw_images/user/order_typeL-1e401baea6.png'
  }]
}, {
  textChannel: ['PLIN', 'PLPT', 'ITIN', 'INUK', 'PLPP', 'THIN', 'IINI'],
  textList: [{
    id: 'A',
    width: 38,
    height: 8,
    wise: 64,
    sh_image: '/pwa_dist/images/user/order_typeA-b933beba1f.png',
    rw_image: '/pwa_dist/rw_images/user/order_typeA-b933beba1f.png'
  }, {
    id: 'B',
    width: 38,
    height: 19,
    wise: 64,
    sh_image: '/pwa_dist/images/user/order_typeB-570d75ead4.png',
    rw_image: '/pwa_dist/rw_images/user/order_typeB-570d75ead4.png'
  }, {
    id: 'C',
    width: 38,
    height: 41,
    wise: 64,
    sh_image: '/pwa_dist/images/user/order_typeC-3ec630d2aa.png',
    rw_image: '/pwa_dist/rw_images/user/order_typeC-3ec630d2aa.png'
  }]
}]

let all_AU_store_list = ['AUPP', 'PAUN', 'AUHB']

let defaultReturnReason = (language = {}) => {
  return [
    {
      client_need_image: 0,
      first_reason_code: '0',
      first_reason_name: language.SHEIN_KEY_PWA_15398,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_15398,
      reason_explanation: '',
      second_return_goods_reason_list: []
    },
    {
      client_need_image: 0,
      first_reason_code: '11',
      first_reason_name: language.SHEIN_KEY_PWA_15404,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_15404,
      reason_explanation: '',
      second_return_goods_reason_list: [
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_17773,
          reason_explanation: '',
          second_reason_code: '1101',
          second_reason_name: language.SHEIN_KEY_PWA_17773
        },
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_15012,
          reason_explanation: '',
          second_reason_code: '1102',
          second_reason_name: language.SHEIN_KEY_PWA_15012
        },
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_15013,
          reason_explanation: '',
          second_reason_code: '1103',
          second_reason_name: language.SHEIN_KEY_PWA_15013
        },
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_15011,
          reason_explanation: '',
          second_reason_code: '1104',
          second_reason_name: language.SHEIN_KEY_PWA_15011
        },
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_17774,
          reason_explanation: '',
          second_reason_code: '1105',
          second_reason_name: language.SHEIN_KEY_PWA_17774
        }
      ]
    },
    {
      client_need_image: 0,
      first_reason_code: '10',
      first_reason_name: language.SHEIN_KEY_PWA_15405,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_15405,
      reason_explanation: '',
      second_return_goods_reason_list: [
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_17773,
          reason_explanation: '',
          second_reason_code: '1101',
          second_reason_name: language.SHEIN_KEY_PWA_17773
        },
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_15012,
          reason_explanation: '',
          second_reason_code: '1102',
          second_reason_name: language.SHEIN_KEY_PWA_15012
        },
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_15013,
          reason_explanation: '',
          second_reason_code: '1103',
          second_reason_name: language.SHEIN_KEY_PWA_15013
        },
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_15011,
          reason_explanation: '',
          second_reason_code: '1104',
          second_reason_name: language.SHEIN_KEY_PWA_15011
        },
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_17774,
          reason_explanation: '',
          second_reason_code: '1105',
          second_reason_name: language.SHEIN_KEY_PWA_17774
        }
      ]
    },
    {
      client_need_image: 0,
      first_reason_code: '3',
      first_reason_name: language.SHEIN_KEY_PWA_15401,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_15401,
      reason_explanation: '',
      second_return_goods_reason_list: [
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_24972,
          reason_explanation: '',
          second_reason_code: '0301',
          second_reason_name: language.SHEIN_KEY_PWA_24972
        },
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_24971,
          reason_explanation: '',
          second_reason_code: '0302',
          second_reason_name: language.SHEIN_KEY_PWA_24971
        }
      ]
    },
    {
      client_need_image: 0,
      first_reason_code: '4',
      first_reason_name: language.SHEIN_KEY_PWA_15402,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_15402,
      reason_explanation: '',
      second_return_goods_reason_list: []
    },
    {
      client_need_image: 0,
      first_reason_code: '5',
      first_reason_name: language.SHEIN_KEY_PWA_15403,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_15403,
      reason_explanation: '',
      second_return_goods_reason_list: []
    },
    {
      client_need_image: 0,
      first_reason_code: '2',
      first_reason_name: language.SHEIN_KEY_PWA_15400,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_15400,
      reason_explanation: '',
      second_return_goods_reason_list: [
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_24973,
          reason_explanation: '',
          second_reason_code: '0201',
          second_reason_name: language.SHEIN_KEY_PWA_24973
        },
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_24974,
          reason_explanation: '',
          second_reason_code: '0202',
          second_reason_name: language.SHEIN_KEY_PWA_24974
        },
        {
          client_need_image: 0,
          image_explanation: '',
          image_list: [],
          language_copy: language.SHEIN_KEY_PWA_24975,
          reason_explanation: '',
          second_reason_code: '0203',
          second_reason_name: language.SHEIN_KEY_PWA_24975
        }
      ]
    },
    {
      client_need_image: 0,
      first_reason_code: '6',
      first_reason_name: language.SHEIN_KEY_PWA_24965,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_24965,
      reason_explanation: '',
      second_return_goods_reason_list: []
    },
    {
      client_need_image: 0,
      first_reason_code: '7',
      first_reason_name: language.SHEIN_KEY_PWA_16787,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_16787,
      reason_explanation: '',
      second_return_goods_reason_list: []
    },
    {
      client_need_image: 0,
      first_reason_code: '8',
      first_reason_name: language.SHEIN_KEY_PWA_16788,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_16788,
      reason_explanation: '',
      second_return_goods_reason_list: []
    },
    {
      client_need_image: 0,
      first_reason_code: '12',
      first_reason_name: language.SHEIN_KEY_PWA_24967,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_24967,
      reason_explanation: '',
      second_return_goods_reason_list: []
    },
    {
      client_need_image: 0,
      first_reason_code: '16',
      first_reason_name: language.SHEIN_KEY_PWA_24966,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_24966,
      reason_explanation: '',
      second_return_goods_reason_list: []
    },
    {
      client_need_image: 0,
      first_reason_code: '1',
      first_reason_name: language.SHEIN_KEY_PWA_15399,
      image_explanation: '',
      language_copy: language.SHEIN_KEY_PWA_15399,
      reason_explanation: '',
      second_return_goods_reason_list: []
    }
  ]
}

export { defaultText, all_AU_store_list, defaultReturnReason }
